import Vue from 'vue'
import Router from 'vue-router'
import store from "../store";

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Pages
const Login = () => import('@/views/pages/Login')
const Home = () => import('@/views/Home')

const SessionNew = () => import('@/views/SessionNew')
const SessionDetail = () => import('@/views/SessionDetail')

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/sessions',
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: '/sessions',
          name: 'Sessions',
          component: Home,
        },
        {
          path: '/sessions/new',
          name: 'New Session',
          component: SessionNew,
        },
        {
          path: '/sessions/:id',
          name: 'Session Detail',
          component: SessionDetail,
        },
      ],
      beforeEnter: ifAuthenticated
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: ifNotAuthenticated
    }
  ]
}

